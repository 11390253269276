body {
  background-color: #5C0C36;
  margin: 0;
  font-family: 'Cocogoose Pro';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-image: url(./imgs/bg.jpg);
  background-size: 100% auto;
  background-repeat: repeat-y;
  background-position: center top;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Cocogoose Pro';
  src: local('Cocogoose Pro'),
    url(./fonts/Cocogoose-Pro-Regular.woff) format('woff'),
    url(./fonts/Cocogoose-Pro-Regular.woff2) format('woff2');
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');


.ribbit:hover {
  cursor: pointer;
}