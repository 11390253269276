.frog {
    max-width: 20%;
    position: absolute;

    transition: all;
    transition-duration: 0.7s;
}

.frog img {
    object-fit: scale-down;
    max-width: 100%;
    max-height: 100%;
}

.frog.hidden {
    transition-timing-function: cubic-bezier(.39, .01, .75, .44);
}

.frog.visible {
    transition-timing-function: cubic-bezier(0, .42, .72, 1.35);
    transform: translate(0, 0);
}

.top-frog {
    left: 10%;
    top: 0%;

    margin-top: -10%;
}

.top-frog.hidden {
    transform: translate(-70%, -100%);
}

.top-frog img {
    transform: rotate(150deg);
}

.left-frog {
    left: 0%;
    top: 30%;

    margin-left: -10%;
}

.left-frog.hidden {
    transform: translate(-100%, 0%);
}

.left-frog img {
    transform: rotate(90deg);
}

.right-frog {
    left: 80%;
    top: 62%;

    overflow: hidden;
}

.right-frog.hidden {
    transform: translate(200%, 0%);
}

.right-frog.visible {
    transform: translate(50%, 0%);
}

.right-frog img {
    transform: rotate(-90deg);
}

.bottom-frog {
    left: 50%;
    top: 100%;

    overflow: hidden;
}

.bottom-frog.hidden {
    transform: translate(-50%, 50%);
}

.bottom-frog.visible {
    transform: translate(-50%, -50%);
}