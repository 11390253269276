.header {
    color: #3A0A31;
}

.header .links {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header .links-list {
    display: flex;
    gap: 0.5em;
}

.header .links-list a {
    width: 2em;
    height: 2em;
}

.header .links-list a>img {
    width: 100%;
    height: 100%;
}



@media only screen and (max-width: 250px) {
    .header .links-list a {
        width: 1em;
        height: 1em;
    }
}