div.footer {
    margin-top: 128px;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: 15%;

    color: white;
}

div.footer p {
    margin-block: 0.1em;
}