.app {
  overflow: hidden;
  position: relative;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.kyogurtah-logo {
  width: 30%;

  display: block;
  margin-left: auto;
  margin-right: auto;

  margin-top: 3rem;

  transform-origin: left;
}

.bg-circles {
  width: 90%;
  position: absolute;
  left: 50%;
  margin-left: -44%;
  min-height: 100px;
  height: auto;

  z-index: -1;

  display: block;
}

#ribbit-audio {
  display: none;
}

@media only screen and (max-width: 767px) {
  .kyogurtah-logo {
    /* margin-top: 1rem; */
  }
}

@media only screen and (max-width: 1100px) {
  .bg-circles {
    max-height: 292px;
    width: auto;
    float: center;

    left: 50%;
    margin-left: -483px;
  }

  .kyogurtah-logo {
    width: 350px;

    max-width: 90%;
  }
}