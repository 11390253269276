.content .games {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    margin-top: 12px;
}

.content .games> {
    width: 100%;
}

.content .games .game-banner-container {
    margin-top: 10%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.text-block {
    margin-top: 64px;
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.text-block .filler {
    width: 30%;
    height: 100%;
}

.text-block>.text {
    font-family: 'Montserrat', sans-serif;
    color: #FFA742;
    text-align: center;
    width: 60%;
}

.text-block>.text h1 {
    font-family: 'Cocogoose Pro', sans-serif;
    color: white;
}

.text-block em {
    color: white;
    font-style: normal;
}

.game-banner {
    width: 70%;
    position: relative;

    transform: rotate(5deg);
}

.game-banner .logo-border {
    z-index: -1;
}

.game-banner .logo-border,
.game-banner .logo {
    position: absolute;
    max-width: 60%;
    object-fit: scale-down;

    top: 0;
    left: 0;
    transform: translate(-30%, -30%) rotate(-15deg);
}

.game-banner .preview {
    object-fit: scale-down;
    max-width: 100%;
    max-height: 100%;

    height: auto;
    width: auto;

    border: 5px solid #A0FB7F;
    border-radius: 2em;
}

.steam-widget {
    margin-top: 28px;
    width: 100%;
    height: 190px;

    border: none;
}

.steam-widget-replacement {
    display: none;
}

@media only screen and (max-width: 767px) {

    .steam-widget {
        display: none;
    }

    .steam-widget-replacement {
        display: block;

        display: flex;
        justify-content: center;
        flex-direction: row;
    }

    .steam-widget-replacement .content {
        margin-top: 24px;
        width: 60%;
        border-radius: 0.5em;
        background: linear-gradient(to right, #75b022 5%, #588a1b 95%);
    }

    .steam-widget-replacement .content a {
        text-decoration: none;
        color: #d2efa9;
        text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.3);
    }

    .steam-widget-replacement .content a h1 {
        margin-block: 0.2em;
        text-align: center;
    }

    .text-block>.text h1 {
        font-size: 1.1em;
    }


    .text-block>.text {
        width: 80%;
    }

    .content .games {
        width: 100%;
    }

    .game-banner .preview {
        border-radius: 1em;
    }

    .text-block .filler {
        width: 0;
    }
}



@media only screen and (max-width: 400px) {
    .steam-widget-replacement .content a h1 {
        margin-block: 0.2em;
        text-align: center;
        font-size: 1.5em;
        margin-left: 0.2em;
        margin-right: 0.2em;
    }
}